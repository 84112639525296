import { LoadUsersBeginAction, LoadUsersFailureAction, LoadUsersSuccessAction } from './admin/actions';

export interface NullAction {
  type: TypeKeys.NULL;
}

// Keep this type updated with each known action
export type ActionTypes = LoadUsersBeginAction | LoadUsersSuccessAction | LoadUsersFailureAction;

export enum TypeKeys {
  // Won't match anything
  NULL = 'NULL',
  ERROR = 'ERROR',
  SET_APPLICATION_CONFIG = 'SET_APPLICATION_CONFIG',
  CHANGE_APPLICATION_CONFIG = 'CHANGE_APPLICATION_CONFIG',
  CHANGE_STATE_UPDATE_TIME = 'CHANGE_STATE_UPDATE_TIME',
  CHANGE_APP_LOADING_STATE = 'CHANGE_APP_LOADING_STATE',
  SET_USER_PROFILE = 'SET_USER_PROFILE',
  CHANGE_APP_AUTH_STATE = 'CHANGE_APP_AUTH_STATE',
  SET_VEHICLES = 'SET_VEHICLES',
  SET_DATA_CONFIG = 'SET_DATA_CONFIG',
  SET_USER_NAME = 'SET_USER_NAME',
  SET_BACK_BUTTON_VISIBILITY = 'SET_BACK_BUTTON_VISIBILITY',
  LOAD_USERS_BEGIN = 'LOAD_USERS_BEGIN',
  LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS',
  LOAD_USERS_FAIL = 'LOAD_USERS_FAIL',
}
